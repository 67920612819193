import logo from './logo.svg'
import letter from './letter.svg'
import error from './error.svg'
import close from './close.svg'
import favicon from './favicon.svg'

export type IconsType = {
  [key: string]: any;
};

const Icons: IconsType = {
	logo,
	letter,
	error,
	close,
	favicon,
}

export default Icons;
