import { IconsType } from "../../shared/icons/default/index";

const colors = {
	primaryButton: '#1414FF',
	primaryButtonHover: '#000093',
	primaryButtonActive: '#000054',
	primaryButtonDisabled: '#D8D8FF',
	primary: "#1D6295",
	secondaryMain: "#002060",
	textPrimary: "#000000",
	textSecondary: "#989A9D",
	textWhite: "#FFFFFF",
	actionPrimary: "#1D6295",
	actionHover: "#002060",
	actionError: "#BD071E",
	secondaryLight: "#83CFFF",
	neutralMedium: "#C6C6C6",
	neutralLight: "#FAFAFA",
	errorBackground: "#faebed",
}

const icons: IconsType = {

}

const defaultTheme = {
	colors, icons
};

export type ThemeType = typeof defaultTheme;
export type StyledThemePropsType = { theme: ThemeType };

export default defaultTheme;
