import gql from "graphql-tag";

const AUTHORIZED_SYSTEM = gql`
  query authorizedSystem {
		authorizedSystem {
			id
			name
		}
	}
`;

export default AUTHORIZED_SYSTEM;
