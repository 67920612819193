import gql from "graphql-tag";

const DEALER_STORES = gql`
  query dealerStores {
    dealerStores {
      id
      name
    }
  }
`;

export default DEALER_STORES;
