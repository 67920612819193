import React, { useContext } from "react";
import { IconsThemeContext } from "../../../themeProviderHOC";
import { Logo, PageDescription } from "./elements";

const Header = () => {
  const { selectedIconsThemeValue } = useContext(IconsThemeContext);
  return (
    <>
      <Logo src={selectedIconsThemeValue["logo"]} />
      <PageDescription>En moins d'une minute, aidez votre client à obtenir sa responsabilité civile</PageDescription>
    </>
  );
};

export default Header;
