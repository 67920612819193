import React, { ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from "react";
import * as yup from "yup";
import { Button, TextField, Header } from "../../shared/elements";
import Message from "../../shared/elements/Message";
import { FormsWrapper, FormsTitle, FormsUserFullName, TextFieldWrapper, FormsTextWrapper, Container } from "./elements";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { VoucherMutationResult, VoucherMutationVariables } from "../../api/interfaces/mutation";
import {
  AdditionalVoucherFieldsType,
  ErrorMessage,
  VoucherFieldsType,
  VoucherFieldsTypeErrors,
} from "../../api/interfaces/voucher";
import EXTERNAL_REQUEST_VOUCHER from "../../api/mutations/externalRequestVoucher";
import { ExecutionResult } from "graphql";
import { AccessContext } from "../../store";
import { VoucherSchema } from "../../shared/validation/voucher";
import GET_PRODUCTS from "../../api/queries/getProducts";
import Dropdown, { DropdownOption } from "../../shared/elements/Dropdown";
import { IconsThemeContext } from "../../themeProviderHOC";
import DEALER_STORES from "../../api/queries/dealerStores";
import { ProductsQueryResult, StoresQueryResult } from "../../api/interfaces/queries";

const initialVoucherErrors: VoucherFieldsTypeErrors = {
  firstName: "",
  lastName: "",
  email: "",
  mobile: "",
  productBrand: "",
  productType: "",
  productSerial: "",
  distributorName: "",
  storeName: "",
  vendorName: "",
};

const Form = () => {
  const { authorizedSystemData } = useContext(IconsThemeContext);
  const { saveAccessInfo } = useContext(AccessContext);
  const { push } = useHistory();
  const [voucherMutation] = useMutation<VoucherMutationResult, VoucherMutationVariables>(EXTERNAL_REQUEST_VOUCHER);
  // NOTE: need to change on BE

  const distributerName = authorizedSystemData.authorizedSystem.name.replace(/Assurlink/i, 'Owen');
  const initialVoucher: VoucherFieldsType = {
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    productBrand: "",
    productType: "Trottinette",
    productSerial: "",
    distributorName: distributerName || "",
    storeName: "",
    vendorName: "",
  };
  const additionalVoucherFields: AdditionalVoucherFieldsType = {
    orderId: "T1",
    discountId: 3,
  };
  const [voucher, setVoucher] = useState<VoucherFieldsType>(initialVoucher);
  const [voucherErrors, setVoucherErrors] = useState<VoucherFieldsTypeErrors>(initialVoucherErrors);

  const [products, setProducts] = useState(null);
  const [brands, setBrands] = useState(null);
  const [stores, setStores] = useState(null);

  const { data, loading } = useQuery<ProductsQueryResult>(GET_PRODUCTS, {
    fetchPolicy: "cache-and-network",
  });

  const { data: dataStores, loading: loadingStores } = useQuery<StoresQueryResult>(DEALER_STORES, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (!data) return;
    const productsData = data.products.map((product) => {
      const { name } = product;
      return { value: name, label: name };
    });
    setProducts(productsData);
  }, [data]);

  useEffect(() => {
    if (!data) return;
    const prodData = data?.products.filter((prod) => {
      return prod.name.toLowerCase() === voucher.productType.toLowerCase();
    });
    const brands = !!prodData.length ? prodData[0].brands : [];
    const prodBrands = brands.map((brand) => {
      const { name } = brand;
      return { value: name, label: name };
    });

    setVoucher((prev) => ({
      ...prev,
      productBrand: prodBrands[0].value,
    }));

    setBrands(prodBrands);
  }, [data, voucher.productType]);

  useEffect(() => {
    if (!dataStores) return;
    const storesData = dataStores.dealerStores.map((store) => {
      const { name } = store;
      return { value: name, label: name };
    });
    setStores(storesData);
  }, [data, dataStores]);

  const typeDropdownOnChange = useCallback(
    (field) => (option: DropdownOption) => {
      return setVoucher((prev) => ({
        ...prev,
        [field]: option.value,
      }));
    },
    []
  );

  const inputsOnChange = useCallback(
    (field) => (event: ChangeEvent<HTMLInputElement>) => {
      let value: string = event.target.value;
      if (field === "mobile") value = value.substring(0, 10);
      return setVoucher((prev) => ({
        ...prev,
        [field]: value,
      }));
    },
    [setVoucher]
  );

  const [checkError, setCheckError] = useState<ErrorMessage>({
    display: false,
    message: "Oups...regardez si tous les champs obligatoires sont correctement renseignés.",
  });
  const hideCheckErrorMessage = useCallback(() => setCheckError({ ...checkError, display: false }), [checkError]);
  const showCheckErrorComponent = checkError.display && !!checkError.message;

  const handleCreateVoucher = useCallback(async () => {
    const validationPassed = await VoucherSchema.validate({ ...voucher }, { abortEarly: false }).catch((e) => {
      setCheckError({ ...checkError, display: true });
      const errors: VoucherFieldsTypeErrors = { ...initialVoucherErrors };
      console.log(e);
      e.inner.map((err: yup.ValidationError) => (errors[err.path] = err.message));
      return setVoucherErrors({ ...errors });
    });

    if (validationPassed) {
      setCheckError({ ...checkError, display: false });
      setVoucherErrors(initialVoucherErrors);
      const voucherWithmodifiedMobile = { ...voucher, mobile: voucher.mobile.substring(1) };
      await voucherMutation({
        variables: { model: { ...voucherWithmodifiedMobile, ...additionalVoucherFields } },
      }).then(async ({ data }: ExecutionResult<VoucherMutationResult>) => {
        if (data) {
          saveAccessInfo({ accessFlag: true });
          return push("/success");
        }
      });
    }
  }, [setCheckError, checkError, voucher, push, voucherMutation, additionalVoucherFields, saveAccessInfo]);

  const enableButton = useMemo(
    () =>
      !!voucher.firstName &&
      !!voucher.lastName &&
      !!voucher.email &&
      !!voucher.mobile &&
      // !!voucher.productSerial &&
      !!voucher.distributorName &&
      !!voucher.storeName &&
      !!voucher.vendorName,
    [voucher]
  );

  return (
    <Container>
      <Header />
      <FormsWrapper>
        <FormsTextWrapper>
          <FormsTitle spaceUnder={showCheckErrorComponent}>Formulaire distributeur</FormsTitle>
          {showCheckErrorComponent ? (
            <>
              <Message
                close={hideCheckErrorMessage}
                icon="error"
                textColor="actionError"
                iconColor="actionError"
                backgroundColor="errorBackground"
                message={checkError.message}
              />
            </>
          ) : null}
          <FormsUserFullName>
            <TextFieldWrapper>
              <TextField
                onChange={inputsOnChange("firstName")}
                label="PRENOM CLIENT"
                error={!!voucherErrors.firstName}
              />
            </TextFieldWrapper>
            <TextFieldWrapper>
              <TextField onChange={inputsOnChange("lastName")} label="NOM CLIENT" error={!!voucherErrors.lastName} />
            </TextFieldWrapper>
          </FormsUserFullName>
          <TextField onChange={inputsOnChange("email")} label="EMAIL" error={!!voucherErrors.email} />
          <TextField
            onChange={inputsOnChange("mobile")}
            label="TELEPHONE MOBILE"
            error={!!voucherErrors.mobile}
            helpText={voucherErrors.mobile}
            value={voucher.mobile}
            type="number"
            placeholder="0XX-XXX-XX-XX"
            prefield
          />
          <Dropdown
            isDisabled={loading}
            label="TYPE DE VEHICULE"
            placeholder=""
            options={products}
            onChange={typeDropdownOnChange("productType")}
            value={{ label: voucher.productType }}
            error={!!voucherErrors.productType}
            upperCaseOptions
          />
          <TextField
            onChange={inputsOnChange("productSerial")}
            label="NUMÉRO DE SÉRIE"
            error={!!voucherErrors.productSerial}
            optional
          />
          {/* <TextField
            onChange={inputsOnChange("productBrand")}
            label="MARQUE DU VEHICULE"
            error={!!voucherErrors.productBrand}
            optional
          /> */}
          <Dropdown
            isDisabled={loading}
            label="MARQUE DU VEHICULE"
            placeholder=""
            options={brands}
            onChange={typeDropdownOnChange("productBrand")}
            value={{ label: voucher.productBrand }}
            error={!!voucherErrors.productBrand}
            upperCaseOptions
          />
          <TextField
            onChange={inputsOnChange("distributorName")}
            label="Nom du distributeur"
            error={!!voucherErrors.distributorName}
            value={voucher.distributorName}
            disabled={true}
          />
          <Dropdown
            isDisabled={loadingStores}
            label="Nom du magasin"
            placeholder=""
            options={stores}
            onChange={typeDropdownOnChange("storeName")}
            value={{ label: voucher.storeName }}
            error={!!voucherErrors.storeName}
            upperCaseOptions
          />
          {/* <TextField onChange={inputsOnChange("storeName")} label="Nom du magasin" error={!!voucherErrors.storeName} /> */}
          <TextField
            onChange={inputsOnChange("vendorName")}
            label="Nom du vendeur"
            error={!!voucherErrors.vendorName}
          />
          <Button onClick={handleCreateVoucher} disabled={!enableButton}>
            VALIDER LA DEMANDE
          </Button>
        </FormsTextWrapper>
      </FormsWrapper>
    </Container>
  );
};

export default Form;
