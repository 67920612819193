import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

const LoginContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

type LogoProps = {
  src: string;
};

const Logo = styled.img<LogoProps>`
  width: 180px;
  height: 48px;
  margin-bottom: 40px;
`;

const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 306px;
  background: #FAFAFA;
  padding: 20px;

  ${media.lg`
		max-width: 480px;
		padding: 40px;
	`};
`;

const LoginTextWrapper = styled.div`
  max-width: 320px;
  width: 100%;
  ${media.lg`
		max-width: 480px;
	`};
`;

const LoginTitle = styled.h1`
  font-weight: bold;
  font-size: 24px;
  line-height: 40px;
  text-align: center;
  color: "#000000";
  margin: 0 0 4px;
  ${media.lg`
		font-size: 32px;
	`};
`;

const LoginDescription = styled.h4`
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: "#002060";
  margin: 0 0 16px;

  ${media.lg`
		font-size: 16px;
	`};
`;

export { LoginContainer, Logo, LoginWrapper, LoginTitle, LoginDescription, LoginTextWrapper };
