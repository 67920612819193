import { media } from "styled-bootstrap-grid";
import styled from "styled-components";
import { StyledThemePropsType } from "../../../config/theme/default";
import HexToRgba from "../../helpers/hexToRgba";
import { DefinedColors } from "../../types/index";

export type TextColor = {
  textColor: DefinedColors;
};

export type BackgroundColor = {
  backgroundColor: DefinedColors;
};

export type Icon = {
  icon: "success" | "warning" | "error";
  iconColor: DefinedColors;
};

const ModalBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: ${(props: StyledThemePropsType) => HexToRgba(props.theme.colors.actionError, 0.5)};

  ${media.xl`
    display: none;
  `};
`;

const MessageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 2px;
  ${media.xl`
   margin: 0; 
  `};
`;

const CrossWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  cursor: pointer;
  height: 30px;
  width: 30px;

  ${media.lg`
		right: 5px;
    top: 3px;
  `};

  ${media.xl`
    display: none;
  `};
`;

const MessageContainer = styled.div<BackgroundColor>`
  z-index: 4;
  background-color: ${(props: StyledThemePropsType & BackgroundColor) => props.theme.colors[props.backgroundColor]};
  width: 100%;
  padding: 53px 7px;
  margin-bottom: 24px;
  border-radius: 6px;
  ${media.lg`
    padding: 14px 20px 18px 11px;  
		display: flex;
  	align-items: center;
  	justify-content: center;
    align-items: center;
  `};
`;

const MessageText = styled.div<TextColor>`
  font-size: 14px;
  position: relative;
  text-align: center;
  color: ${(props: StyledThemePropsType & TextColor) => props.theme.colors[props.textColor]};
  line-height: 20px;
  ${media.lg`
		text-align: initial;
		margin-left: 15px;
	`};
	${media.xl`
		margin-left: 0;
	`};
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
  outline: transparent;
  border: none;

  ${media.xl`
    display: flex;
    justify-content: center;
    align-items: center;
  `};
`;

export { MessageWrapper, MessageContainer, ModalBackground, MessageText, IconWrapper, CrossWrapper };
