import * as yup from "yup";

const firstName = yup
	.string()
	.required()
	;

const lastName = yup
	.string()
	.required()
	;

const email = yup
	.string()
	.email()
	.required()
	;

const mobile = yup
	.string()
	.required()
	.matches(/^0(\+\d{1,3}[- ]?)?\d{9}$/, 'Numéro non valide')
	.length(10)
	;

const productType = yup
	.string()
	.required()
	;

const productBrand = yup
	.string()
	;

const productSerial = yup
	.string()
	// .required()
	;

const distributorName = yup
	.string()
	.required()
	;
const storeName = yup
	.string()
	.required()
	;

const vendorName = yup
	.string()
	.required()
	;

export const VoucherSchema = yup.object({ firstName, lastName, email, mobile, productBrand, productSerial, distributorName, storeName, vendorName, productType });
