import { useQuery } from "@apollo/client";
import React, { ReactElement, useEffect, useState } from "react";
import "react-app-polyfill/stable";

import { ThemeProvider } from "styled-components";
import { AuthorizedSystemQueryResult } from "./api/interfaces/queries";
import AUTHORIZED_SYSTEM from "./api/queries/authorizedSystem";

import defaultTheme, { ThemeType } from "./config/theme/default";
import { Loader } from "./shared/elements";

import IconsDefaultTheme, { IconsType } from "./shared/icons/default";
import IconsSecondTheme from "./shared/icons/second";
import IconsCoveaTheme from "./shared/icons/covea";
import coveaTheme from "./config/theme/covea";

interface IThemeProviderHOC {
  children: ReactElement;
}

const defaultIconsTheme = {
  ...IconsDefaultTheme,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const secondIconsTheme = {
  ...IconsDefaultTheme,
  ...IconsSecondTheme,
};

const coveaIconsTheme = {
  ...IconsDefaultTheme,
  ...IconsCoveaTheme,
};

export const IconsThemeContext = React.createContext(IconsDefaultTheme);

const ThemeProviderHOC = ({ children }: IThemeProviderHOC) => {
  const { data, loading } = useQuery<AuthorizedSystemQueryResult>(AUTHORIZED_SYSTEM, {
    fetchPolicy: "cache-and-network",
  });
  const isAuth = localStorage.getItem("AUTH_TOKEN");
  const [selectedTheme, setSelectedTheme] = useState<ThemeType>({
    ...defaultTheme,
    icons: IconsDefaultTheme,
  });
  const [selectedIconsTheme, setSelectedIconsTheme] = useState<IconsType>(defaultIconsTheme);
  const [selectedNameTheme, setSelectedNameTheme] = useState<string>("Owen");

  useEffect(() => {
    if (!loading && !!data && !!isAuth) {
      const systemId = data.authorizedSystem.id;
      const isCoveaTheme = systemId === 9;
      if (isCoveaTheme) {
        setSelectedTheme({ ...coveaTheme, icons: IconsCoveaTheme });
        setSelectedIconsTheme(coveaIconsTheme);
        setSelectedNameTheme("Covea");
      } else {
        setSelectedTheme({ ...defaultTheme, icons: IconsDefaultTheme });
        setSelectedIconsTheme(defaultIconsTheme);
        setSelectedNameTheme("Owen");
      }
    }
  }, [loading, data, isAuth]);

  if (loading) {
    return <Loader isLoading />;
  }

  return (
    <ThemeProvider theme={selectedTheme}>
      <IconsThemeContext.Provider
        value={{
          selectedIconsThemeValue: selectedIconsTheme,
          selectedNameThemeValue: selectedNameTheme,
          setDefaultTheme: setSelectedTheme,
          setDefaultIcons: setSelectedIconsTheme,
          authorizedSystemData: data,
        }}
      >
        {children}
      </IconsThemeContext.Provider>
    </ThemeProvider>
  );
};

export default ThemeProviderHOC;
