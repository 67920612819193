import { ChangeEvent } from "react";
import { media } from "styled-bootstrap-grid";
import styled, { css } from "styled-components";
import { StyledThemePropsType } from "../../../config/theme/default";

type InputProps = {
  name?: string;
  type?: "text" | "number";
  placeholder?: string;
  disabled?: boolean;
  value?: string | number;
  onChange(event: ChangeEvent<HTMLInputElement>): void;
  prefield?: boolean;
  inputmode?: string;
};

export type WrapperProps = {
  children?: any;
  error?: boolean;
  disabled?: boolean;
  height?: string;
};

const TextFieldContainer = styled.div`
  width: 100%;
`;

const LabelContainer = styled.div`
  margin-bottom: 5px;
  color: ${(props: StyledThemePropsType) => props.theme.colors.textPrimary};
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
`;
const LabelSpan = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: ${(props: StyledThemePropsType) => props.theme.colors.textSecondary};
  text-transform: lowercase;
`;

const InputStyles = css`
  color: ${(props: StyledThemePropsType) => props.theme.colors.textPrimary};
  font-size: 16px;
  &::placeholder {
    color: ${(props: StyledThemePropsType) => props.theme.colors.textPrimary};
    font-weight: normal;
    font-style: italic;
  }

  text-transform: ${(props: any) => (props.capitalize ? props.capitalize : "none")};
  background-color: transparent;
  position: relative;
  width: 100%;
  font-weight: normal;
  border: none;
  outline: none;
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    display: none;
  }
`;

const Input = styled.input<InputProps>`
  ${InputStyles};
  padding-left: ${({ prefield }: InputProps) => (prefield ? "60px" : "0px")};
`;

export const WrapperDisabled = css<StyledThemePropsType>`
  background: ${(props: StyledThemePropsType) => props.theme.colors.textSecondary};
  border: 1px solid ${(props: StyledThemePropsType) => props.theme.colors.textSecondary};
`;

export const WrapperError = css<StyledThemePropsType>`
  border: 1px solid ${(props: StyledThemePropsType) => props.theme.colors.actionError};
`;

const WrapperStyles = css`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 10px;
  border-radius: 6px;
  background: ${(props: StyledThemePropsType) => props.theme.colors.textWhite};
  border: 1px solid ${(props: StyledThemePropsType) => props.theme.colors.neutralMedium};

  &:focus-within {
    border: 1px solid ${(props: StyledThemePropsType) => props.theme.colors.textSecondary};
  }

  ${(props: WrapperProps & StyledThemePropsType) => {
    if (props.error) {
      return WrapperError;
    }
  }};
`;

const Wrapper = styled.div<WrapperProps & StyledThemePropsType>`
  ${WrapperStyles};
  height: ${(props: WrapperProps) => (props.height ? props.height : "50px")};
  position: relative;
  margin-bottom: 24px;
  margin-bottom: ${(props: WrapperProps) => (props.error ? "10px" : "24px")};
`;

const PreFieldContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 100%;
  background: ${(props: StyledThemePropsType) => props.theme.colors.neutralLight};
  border-radius: 5px 0px 0px 5px;
  border-right: 1px solid ${(props: StyledThemePropsType) => props.theme.colors.neutralMedium};
`;

// TODO: check it
const WrapperWithoutPaddings = styled(Wrapper)`
  padding: 0;
  height: ${(props: WrapperProps) => (props.height ? props.height : "50px")};
`;

type HelpTextProps = {
  error?: boolean;
  children: string | number;
};
// TODO: check it
const HelpTextError = css<StyledThemePropsType>`
  color: ${(props: StyledThemePropsType) => props.theme.colors.actionError};
`;

const HelpText = styled.div<HelpTextProps & StyledThemePropsType>`
  color: ${(props: StyledThemePropsType) => props.theme.colors.textSecondary};
  ${(props: HelpTextProps & StyledThemePropsType) => props.error && HelpTextError};
  margin-bottom: 10px;
`;

const TextFieldBottomMarginStyles = css`
  margin-bottom: 25px;
  width: 100%;
  ${media.lg`
    margin-bottom: 32px;
  `};
`;

const TextFieldBottomMargin = styled.div`
  ${TextFieldBottomMarginStyles};
`;

export {
  TextFieldContainer,
  LabelContainer,
  Wrapper,
  Input,
  HelpText,
  TextFieldBottomMarginStyles,
  TextFieldBottomMargin,
  WrapperWithoutPaddings,
  InputStyles,
  WrapperStyles,
  LabelSpan,
  PreFieldContainer,
};
