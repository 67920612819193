import React, { FunctionComponent } from "react";
import CustomIcon from "../Icon";
import {
  BackgroundColor,
  CrossWrapper,
  Icon,
  IconWrapper,
  MessageContainer,
  MessageText,
  MessageWrapper,
  TextColor,
} from "./elements";

type MessageProps = TextColor &
  BackgroundColor &
  Icon & {
    message: string | JSX.Element;
    close(): void;
    hiddenIcon?: boolean;
  };

const Message: FunctionComponent<MessageProps> = ({
  icon,
  iconColor,
  backgroundColor,
  textColor,
  message,
  hiddenIcon,
  close,
}) => {
  return (
    <MessageWrapper>
      <MessageContainer backgroundColor={backgroundColor}>
        <IconWrapper>
          <CustomIcon icon={icon} iconColor={iconColor} height="24" width="24" margin="0 17px 0 14px" />
        </IconWrapper>
        {hiddenIcon ? null : (
          <CrossWrapper onClick={close}>
            <CustomIcon icon="close" iconColor={iconColor} height="10" width="10" />
          </CrossWrapper>
        )}
        <MessageText textColor={textColor}>{message}</MessageText>
      </MessageContainer>
    </MessageWrapper>
  );
};

export default Message;
