import { ApolloProvider } from "@apollo/client";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { GridThemeProvider } from "styled-bootstrap-grid";
import gridTheme from "./config/theme/grid";
import Root from "./routing/index";
import client from "./api/client";
import Store from "./store/index";
import ThemeProviderHOC from "./themeProviderHOC";


ReactDOM.render(
  <React.StrictMode>
		<ApolloProvider client={client}>
    <ThemeProviderHOC>
      <GridThemeProvider gridTheme={gridTheme}>
				<Store>
        <Root />
				</Store>
      </GridThemeProvider>
			</ThemeProviderHOC>
		</ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);
