import { IconsType } from "../../shared/icons/second/index";

const colors = {
    primaryButton: '#1414FF',
    primaryButtonHover: '#000093',
    primaryButtonActive: '#000054',
    primaryButtonDisabled: '#D8D8FF',
    primary: "#F09A00",
    secondaryMain: "#C8102E",
    textPrimary: "#000",
    textSecondary: "#989A9D",
    textWhite: "#FFFFFF",
    actionPrimary: "#1D6295",
    actionHover: "#B27300",
    actionError: "#BD071E",
    secondaryLight: "#F09A00",
    neutralMedium: "#C6C6C6",
    neutralLight: "#FAFAFA",
    errorBackground: "#faebed",
}

const icons: IconsType = {

}

const coveaTheme = {
    colors, icons
};

export type ThemeType = typeof coveaTheme;
export type StyledThemePropsType = { theme: ThemeType };

export default coveaTheme;
