import styled, { css } from "styled-components";
import { StyledThemePropsType } from "../../../config/theme/default";
import Icons from "../../icons/default";
import { DefinedColors, DefinedIcons } from "../../types/index";

type CustomIconProps = {
  icon: DefinedIcons;
  iconColor?: DefinedColors;
  hoverColor?: DefinedColors;
  isHovered?: boolean;
  opacity?: number;
  width?: string;
  height?: string;
  margin?: string;
};

const height = css<CustomIconProps>`
  height: ${(props: CustomIconProps) => props.height}px;
`;

const width = css<CustomIconProps>`
  width: ${(props: CustomIconProps) => props.width}px;
`;

const margin = css<CustomIconProps>`
  margin: ${(props: CustomIconProps) => props.margin};
`;

const opacity = css<CustomIconProps>`
  opacity: ${(props: CustomIconProps) => props.opacity};
`;

const CustomIcon = styled.div<CustomIconProps>`
  display: inline-block;
  ${(props: CustomIconProps) => props.opacity && opacity};
  ${(props: CustomIconProps) => props.margin && margin};
  ${(props: CustomIconProps) => props.height && height};
  ${(props: CustomIconProps) => props.width && width};
  background-color: ${(props: StyledThemePropsType & CustomIconProps) => {
    const colorOnHover = props.hoverColor ? props.theme.colors[props.hoverColor] : props.theme.colors.neutralLight;
    const defaultColor = props.theme.colors[props.iconColor];

    return props.isHovered ? colorOnHover : defaultColor;
  }};
  mask: url(${(props: CustomIconProps & StyledThemePropsType) => Icons[props.icon]}) no-repeat center;
  mask-size: contain;
`;

export default CustomIcon;
