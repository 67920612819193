import React, { FunctionComponent, useCallback, useState } from "react";

export type AccessFlag = {
  accessFlag: boolean;
};
export type UserKeyFlag = {
  isUserKey: boolean;
};
export const initialAccessFlag: AccessFlag = {
  accessFlag: false,
};
export const initialUserKey: UserKeyFlag = {
  isUserKey: false,
};

type AccessContext = AccessFlag &
  UserKeyFlag & {
    saveAccessInfo(modifiedData: AccessFlag): void;
    saveUserKey(modifiedData: UserKeyFlag): void;
  };

export const AccessContext = React.createContext<AccessContext>({
  ...initialAccessFlag,
  ...initialUserKey,
  saveAccessInfo(modifiedData: AccessFlag): AccessFlag {
    return modifiedData;
  },
  saveUserKey(modifiedData: UserKeyFlag): UserKeyFlag {
    return modifiedData;
  },
});

export const AccessContextProvider = AccessContext.Provider;

const Store: FunctionComponent = (props) => {
  const token = localStorage.getItem("AUTH_TOKEN");
  const [accessFlag, setAccessFlag] = useState<AccessFlag>({ ...initialAccessFlag });

  const [isUserKey, setIsUserKey] = useState<UserKeyFlag>({ isUserKey: !!token });

  const saveAccessInfo = useCallback((modifiedData: AccessFlag) => setAccessFlag({ ...modifiedData }), []);
  const saveUserKey = useCallback((modifiedData: UserKeyFlag) => setIsUserKey({ ...modifiedData }), []);

  localStorage.setItem("user", `${isUserKey.isUserKey}`);
  return (
    <AccessContextProvider value={{ ...accessFlag, ...isUserKey, saveAccessInfo, saveUserKey }}>
      {props.children}
    </AccessContextProvider>
  );
};

export default Store;
