import styled, { css } from "styled-components";
import { media } from "styled-bootstrap-grid";
import { StyledThemePropsType } from "../../../config/theme/default";
import { HexToRgba } from "../../helpers";

export type ButtonContainerProps = {
  disabled?: boolean;
};

const disabledButtonStyles = css`
  box-shadow: unset;
  background-color: ${(props: StyledThemePropsType) => HexToRgba(props.theme.colors.primary, 0.5)};
  color: ${(props: StyledThemePropsType) => props.theme.colors.textWhite};
  border: none;
  cursor: unset;
`;

const ButtonContainer = styled.button<ButtonContainerProps>`
  width: 100%;
  text-transform: uppercase;
  padding: 14px 17px;
  background-color: ${(props: StyledThemePropsType) => props.theme.colors.primaryButton};
  color: ${(props: StyledThemePropsType) => props.theme.colors.textWhite};
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  outline: none;
  ${({ disabled }: ButtonContainerProps) => (disabled ? disabledButtonStyles : null)}


  &:hover {
    background-color: ${(props: StyledThemePropsType) => props.theme.colors.primaryButtonHover}
  }
  &:active {
    background-color: ${(props: StyledThemePropsType) => props.theme.colors.primaryButtonActive}
  }
  &:disabled {
    background-color: ${(props: StyledThemePropsType) => props.theme.colors.primaryButtonDisabled}
  }

  ${media.lg`
		padding: 19px 0;
	`};
`;

export { ButtonContainer };
