// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../node_modules/postcss-loader/src/index.js??postcss!slick-carousel/slick/slick.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../node_modules/postcss-loader/src/index.js??postcss!slick-carousel/slick/slick-theme.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
exports.push([module.id, "/* Slick dots customization */\n\n@font-face {\n  font-family: \"Lexend\";\n  font-style: normal;\n  src: url(\"/shared/fonts/Lexend-Regular.ttf\") format(\"ttf\");\n}\n\n.slick-dots li button:before {\n  color: #81bc00;\n  opacity: .3;\n  font-size: 10px;\n}\n\n.slick-dots li.slick-active button:before {\n  color: #81bc00;\n  font-size: 12px;\n}\n\nbody {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n\t-moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n* {\n  font-family: 'Lexend', Arial, sans-serif;\n  box-sizing: border-box;\n}\n\nhtml {\n  box-sizing: border-box;\n  -ms-overflow-style: scrollbar;\n}\n\n*,\n*:before,\n*:after {\n  box-sizing: inherit;\n}\n", ""]);
// Exports
module.exports = exports;
