import React, { ChangeEvent, useCallback, useContext, useState } from "react";
import { LoginContainer, Logo, LoginWrapper, LoginTitle, LoginDescription, LoginTextWrapper } from "./elements";
import { TextField, Button } from "../../shared/elements";
import { useHistory } from "react-router-dom";
import AUTHORIZE from "../../api/mutations/authorize";
import { AuthorizeMutationResult, AuthorizeMutationVariables } from "../../api/interfaces/mutation";
import { useMutation } from "@apollo/client";
import { ExecutionResult } from "graphql";
import { AuthorizeSchema } from "../../shared/validation/login";
import { IconsThemeContext } from "../../themeProviderHOC";
import defaultTheme from "../../config/theme/default";
import IconsDefaultTheme from "../../shared/icons/default";
import { AccessContext } from "../../store";

const Login = () => {
  const { saveUserKey } = useContext(AccessContext);
  localStorage.removeItem("AUTH_TOKEN");

  const { selectedIconsThemeValue, setDefaultTheme, setDefaultIcons } = useContext(IconsThemeContext);
  setDefaultTheme(defaultTheme);
  setDefaultIcons(IconsDefaultTheme);

  const [authField, setAuthFieldError] = useState(false);
  const [userKey, setUserKey] = useState("");
  const { push } = useHistory();
  const [authorizeMutation] = useMutation<AuthorizeMutationResult, AuthorizeMutationVariables>(AUTHORIZE);

  const inputsOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setUserKey(event.target.value);
    },
    [setUserKey]
  );

  const handlerLogIn = useCallback(async () => {
    const validationPassed = await AuthorizeSchema.validate({ userKey }, { abortEarly: false }).catch(() => {
      setAuthFieldError(true);
    });

    if (validationPassed) {
      setAuthFieldError(false);
      await authorizeMutation({ variables: { key: userKey } })
        .then(async ({ data }: ExecutionResult<AuthorizeMutationResult>) => {
          if (data) {
            saveUserKey({ isUserKey: true });
            localStorage.setItem("AUTH_TOKEN", data.authorize);
            return push("/voucher");
          }
        })
        .catch((e) => {
          setAuthFieldError(true);
          console.log(e);
        });
    }
  }, [userKey, authorizeMutation, push, saveUserKey]);

  return (
    <LoginContainer>
      <Logo src={selectedIconsThemeValue["logo"]} />
      <LoginWrapper>
        <LoginTextWrapper>
          <LoginTitle>Bonjour</LoginTitle>
          <LoginDescription>Saisissez votre Pass ci-dessous</LoginDescription>
          <TextField onChange={inputsOnChange} label="PASS" error={authField} />
          <Button onClick={handlerLogIn} disabled={!userKey}>
            LOG IN
          </Button>
        </LoginTextWrapper>
      </LoginWrapper>
    </LoginContainer>
  );
};

export default Login;
