import styled from "styled-components";
import { StyledThemePropsType } from "../../../config/theme/default";

export const DropdownWrapper = styled.div`
  width: 100%;
`;

export const LabelContainer = styled.div`
  margin-bottom: 5px;
  color: ${(props: StyledThemePropsType) => props.theme.colors.textPrimary};
`;
