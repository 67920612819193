import React, { useContext } from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";
import Form from "../pages/form";
import Login from "../pages/login";
import SuccessPage from "../pages/succes";
import { AccessContext } from "../store";
import ThemeProviderHOC, { IconsThemeContext } from "../themeProviderHOC";
const Root = () => {
  const { accessFlag, isUserKey } = useContext(AccessContext);
  const { selectedIconsThemeValue, selectedNameThemeValue } = useContext(IconsThemeContext);

  return (
    <>
      <Helmet>
        <meta name="description" content={selectedNameThemeValue} />
        <title>{selectedNameThemeValue}</title>
        <link rel="icon" type="image/png" sizes="32x32" href={selectedIconsThemeValue["favicon"]} />
        <link rel="icon" type="image/png" sizes="16x16" href={selectedIconsThemeValue["favicon"]} />
        <link rel="apple-touch-icon" sizes="180x180" href={selectedIconsThemeValue["favicon"]} />
        <link rel="mask-icon" href={selectedIconsThemeValue["favicon"]} />
      </Helmet>
      <Router>
        <Switch>
          <Route path="/login" component={Login} />
          {isUserKey ? (
              <ThemeProviderHOC>
                <>
                  <Route path="/voucher" component={Form} />
                  {accessFlag ? <Route path="/success" component={SuccessPage} /> : null }
                  <Redirect to="/voucher" />
                </>
              </ThemeProviderHOC>
          ) : (
            <Redirect to="/login" />
          )}
        </Switch>
      </Router>
    </>
  );
};

export default Root;
